<template>
  <div>
    <b-container fluid class="border-box">
      <b-row class="border-box">
        <b-col xl="12" md="12" class="border-box mb-4">
          <h3>
            <span>{{ $t("createNotificationTitle") }}</span>
          </h3>

          <div class="container-alb">
            <b-col xl="12" md="12" class="border-box mb-4">
              <p>
                <strong>{{ $t("createNotificationSubject") }}</strong>
                <br>
                <input type="text" class="formedituser" v-model="subject">
              </p>
              <br>
              <p>
                <strong>{{ $t("createNotificationBody") }}</strong>
                <br>
                <br>
                <vue-editor v-model="content"></vue-editor>
              </p>
              <br>
              <p>
                <strong>{{ $t("createNotificationValidity") }}</strong>
                <br>
                <br>
                <date-range
                  :time="this.validity"
                  :openDirection="true"
                  :noRanges="true"
                />
              </p>
              <br>
              <p>
                <strong>{{ $t("createNotificationTarget") }}</strong>
                <br>
                <br>
                <b-form-radio-group
                  id="notificationTarget"
                  v-model="notificationTarget"
                  name="notification-target"
                  class="notification-target"
                >
                  <b-form-radio value="false">{{
                    $t("createNotificationTargetAllEmployees")
                  }}</b-form-radio>
                  <b-form-radio value="true">{{
                    $t("createNotificationTargetSelectEmployees") + "(" + selectedBeneficiaries.length + ")"
                  }}</b-form-radio>
                </b-form-radio-group>
              </p>
              <div v-if="notificationTarget === 'true'" id="expandBeneficiary">
                <table 
                  cellpadding="10" 
                  cellspacing="0" 
                  width="95%" 
                  align="center"
                >
                  <tr>
                    <td>
                      <h3>
                        {{ $t("beneficiariesTitle") }}
                      </h3>
                    </td>
                    <td align="right">
                      <div style="position: relative">
                        <form>
                          <input
                            type="text"
                            :placeholder="$t('beneficiariesFilterKeyword')"
                            class="formedituser search-input"
                            v-model="filters.keyword"
                            v-on:keyup.enter="loadCustomerBeneficiaries()"
                          />
                          <a
                            class="search-btn cursor-pointer"
                            @click="loadCustomerBeneficiaries()"
                          ></a>
                        </form>
                      </div>
                    </td>
                  </tr>
                </table>

                <div class="loading" v-if="this.customerBeneficiaries === null">
                  <img src="@/assets/images/loading.gif" />
                </div>

                <table
                  cellpadding="10"
                  cellspacing="0"
                  width="95%"
                  class="hover-table invoices-table"
                  align="center"
                  v-else
                >
                  <tr>
                    <td valign="middle"></td>
                    <td valign="middle">
                      <strong>{{ $t("beneficiariesTableName") }}</strong>
                    </td>
                    <td class="priority-0" valign="middle">
                      <strong>{{ $t("beneficiariesTableSurname") }}</strong>
                    </td>
                    <td class="priority-1" valign="middle">
                      <strong>{{ $t("beneficiariesTableCNP") }}</strong>
                    </td>
                    <td class="priority-2" valign="middle">
                      <strong>{{ $t("beneficiariesTableCard") }}</strong>
                    </td>
                  </tr>
                  <tr v-if="this.customerBeneficiaries.length === 0">
                    <td colspan="10">{{ $t("noBeneficiariesInfoMessage") }}</td>
                  </tr>
                  <template v-for="(entry, index) in this.customerBeneficiaries">
                    <tr
                      :key="index"
                      class="trhover parent"
                      :class="index % 2 === 0 && 'gri-cell'"
                    >
                      <td valign="middle">
                        <input
                          type="checkbox"
                          @change="beneficiaryChecked($event.target, entry)"
                          :checked="isBeneficiaryChecked(entry) >= 0"
                        />
                      </td>
                      <td valign="middle">
                        <a
                          class="detailsOrderMobile priority-show-5 display-inline-block"
                          @click="selectBeneficiaryMobile(index)"
                        >
                          <font-awesome-icon
                            icon="minus-circle"
                            v-if="detailsMobile && entry === selectedBeneficiary"
                          />
                          <font-awesome-icon icon="plus-circle" v-else />&nbsp;&nbsp;
                        </a>
                        <span>{{ entry.last_name }}</span>
                      </td>
                      <td class="priority-0" valign="middle">{{ entry.first_name }}</td>
                      <td class="priority-1" valign="middle">{{ entry.cnp }}</td>
                      <td class="priority-2" valign="middle">
                        <img
                          :src="
                            require('@/assets/images/bullet_' +
                              entry.accounts[0].product +
                              '.svg')
                          "
                        />
                        &nbsp;&nbsp;{{ getProductNameMethod(entry.accounts[0].product) }}
                      </td>
                    </tr>
                    <tr
                      :key="customerBeneficiaries.length + index"
                      class="child"
                      :class="index % 2 === 0 && 'gri-cell'"
                      v-show="detailsMobile && entry === selectedBeneficiary"
                    >
                      <td colspan="12">
                        <p class="priority-show-0">
                          <strong
                            >{{ $t("beneficiariesTableSurname") }} : &nbsp;&nbsp;</strong
                          >
                          <span>{{ entry.first_name }}</span>
                        </p>
                        <p class="priority-show-1">
                          <strong
                            >{{ $t("beneficiariesTableCNP") }} : &nbsp;&nbsp;</strong
                          >
                          <span>{{ entry.cnp }}</span>
                        </p>
                        <p class="priority-show-2">
                          <strong
                            >{{ $t("beneficiariesTableCard") }} : &nbsp;&nbsp;</strong
                          >
                          <span>
                            <img
                              :src="require('@/assets/images/bullet_' + entry.accounts[0].product + '.svg')"
                            />
                            &nbsp;&nbsp;{{
                              getProductNameMethod(entry.accounts[0].product)
                            }}
                          </span>
                        </p>
                      </td>
                    </tr>
                  </template>
                </table>

                <div class="pagination-wrapper border-box">
                  <div class="pagination-wrapper border-box">
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="this.beneficiariesCount"
                      :per-page="perPage"
                      size="sm"
                    ></b-pagination>
                  </div>
                </div>
              </div>
              <br>
              <br>
              <p>
                <a class="notification-action">{{ $t("createNotificationSentButton") }}</a>
              </p>
            </b-col>
          </div>

          
        </b-col>
      </b-row>
      <b-row class="border-box">
        <b-col xl="12" md="12" class="border-box mb-4">
          <h3>
            <span>{{ $t("notificationListSend") }}</span>
          </h3>

          <!-- <p>{{ $t("noNotifications") }}</p> -->

         <div class="container-alb">
            <table
            cellpadding="10"
            cellspacing="0"
            width="95%"
            class="hover-table invoices-table"
            align="center"
          >
            <tr>
              <td valign="middle">
                <strong>{{ $t("createNotificationSubject") }}</strong>
              </td>
              <td class="priority-0" valign="middle">
                <strong>{{ $t("createNotificationBody") }}</strong>
              </td>
              <td class="priority-1" valign="middle">
                <strong>{{ $t("createNotificationValidity") }}</strong>
              </td>
              <td class="priority-2" valign="middle">
                <strong>{{ $t("notificationLabelEmployee") }}</strong>
              </td>
            </tr>
            <tr v-if="notifications.length === 0">
              <td colspan="10">{{ $t("noBeneficiariesInfoMessage") }}</td>
            </tr>
            <template v-for="(entry, index) in notifications">
              <tr
                :key="index"
                class="trhover parent"
                :class="index % 2 === 0 && 'gri-cell'"
              >
                <td valign="middle">
                  <a
                    class="detailsOrderMobile priority-show-5 display-inline-block"
                    @click="selectBeneficiaryMobile(index)"
                  >
                    <font-awesome-icon
                      icon="minus-circle"
                      v-if="detailsMobile && entry === selectedBeneficiary"
                    />
                    <font-awesome-icon icon="plus-circle" v-else />&nbsp;&nbsp;
                  </a>
                  <span>{{ entry.title }}</span>
                </td>
                <td class="priority-0" valign="middle">{{ entry.message }}</td>
                <td class="priority-1" valign="middle">{{ entry.cnp }}</td>
                <td class="priority-2" valign="middle">{{ entry.validTo }}</td>
              </tr>
              <tr
                :key="customerBeneficiaries.length + index"
                class="child"
                :class="index % 2 === 0 && 'gri-cell'"
                v-show="detailsMobile && entry === selectedBeneficiary"
              >
                <td colspan="12">
                  <p class="priority-show-0">
                    <strong
                      >{{ $t("beneficiariesTableSurname") }} : &nbsp;&nbsp;</strong
                    >
                    <span>{{ entry.first_name }}</span>
                  </p>
                  <p class="priority-show-1">
                    <strong
                      >{{ $t("beneficiariesTableCNP") }} : &nbsp;&nbsp;</strong
                    >
                    <span>{{ entry.cnp }}</span>
                  </p>
                  <p class="priority-show-2">
                    <strong
                      >{{ $t("beneficiariesTableCard") }} : &nbsp;&nbsp;</strong
                    >
                    <span>
                      <img
                        :src="require('@/assets/images/bullet_' + entry.accounts[0].product + '.svg')"
                      />
                      &nbsp;&nbsp;{{
                        getProductNameMethod(entry.accounts[0].product)
                      }}
                    </span>
                  </p>
                </td>
              </tr>
            </template>
          </table>
         </div>
        </b-col>
      </b-row>
      <b-row class="border-box">
        <b-col xl="12" md="12" class="border-box mb-4">
          <h3>
            <span>{{ $t("notificationListReceived") }}</span>
          </h3>

          <!-- <p>{{ $t("noNotifications") }}</p> -->

          <div
            v-for="(notification, index) in notifications"
            :key="index"
            class="notification-block"
          >
            <div class="notification-title">
              <img v-if="notification.read" src="@/assets/images/happy.png" alt />
              <img v-else src="@/assets/images/sceptic.png" alt />
              <h6>{{ notification.dateIssued | fromNow }}</h6>
            </div>
            <div class="notification-content">
              <div class="d-block w-100">
                <h6>{{ notification.title }}</h6>
                <p>
                  <read-more
                    class="offer-description"
                    :moreStr="$t('readMore')"
                    :text="notification.message"
                    link="#"
                    :lessStr="$t('readLess')"
                    :max-chars="100"
                  ></read-more>
                </p>
              </div>
            </div>
            <div class="notification-footer">
              <p>
                <label class="cursor-pointer custom-checkbox" :class="(selectedNotifications.includes(notification)) && 'culoare-11'">
                  <font-awesome-icon icon="check-circle" size="3x"/>
                  <input 
                    type="checkbox" 
                    :id="'checkbox' + index"
                    @change="notificationChecked($event.target, notification)"
                  />
                </label>
              </p>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row v-if="selectedNotifications.length > 0" id="actionSelectedElement" class="border-box">
        <b-col xl="12" md="12" class="border-box">
          <h3>
            <span>{{ $t("beneficiariesTableAction") }}</span>
          </h3>
          <div class="container-alb pl-4 pr-4">
            <p class="mb-4">
              {{ $t("actionsNotificationText", { count: this.selectedNotifications.length }) }}
            </p>
            <b-row>
              <b-col cols="3" sm="2" md="2" class="border-box">
                <span>{{ $t("notificationActionSelect") }}:</span>
              </b-col>
              <b-col cols="9" sm="6" md="6" class="border-box">
                <select @change="actionSelected" class="filtrare notification-action-select">
                  <option
                    v-for="(entry, index) in notificationActions"
                    :key="index"
                    :value="entry"
                    :selected="selectedAction === entry"
                  >
                    {{ $t("notificationAction_" + entry) }}
                  </option>
                </select>
              </b-col>
            </b-row>
            <a
              class="status-background background-active mt-4"
              href="#"
              @click="validateSelection()"
              >{{ $t("confirmButtonLabel") }}</a
            >
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import moment from "moment";
import { BContainer, BRow, BCol, BFormRadio, BFormRadioGroup, BPagination } from "bootstrap-vue";
import { VueEditor } from "vue2-editor";
import DateRange from "@/components/DateRange.vue";
import httpServiceCustomer from "@/http/http-customer.js";
import Cookies from "js-cookie";
import utils from "@/plugins/utils.js";

export default {
  name: "customer_notifications",
  props: {
    notifications: Array,
    companyDetails: Object,
    user: Object
  },
  components: {
    "b-container": BContainer,
    "b-row": BRow,
    "b-col": BCol,
    VueEditor,
    DateRange,
    "b-form-radio": BFormRadio,
    "b-form-radio-group": BFormRadioGroup,
    "b-pagination": BPagination,
  },
  data() {
    return {
      selectedNotifications: [],
      isSelected: false,
      notificationSelected: null,
      selectedAction: "READ",
      notificationActions: [
        "DELETE",
        "READ",
      ],
      subject: "",
      content: "",
      validity: [moment(), moment().add(1, "week")],
      notificationTarget: "",
      filters: {
        product: 11,
        keyword: ""
      },
      customerBeneficiaries: null,
      beneficiariesCount: 0,
      perPage: 10,
      currentPage: 1,
      selectedBeneficiary: null,
      detailsMobile: null,
      selectedBeneficiaries: []
    };
  },
  filters: {
    fromNow(date) {
      return moment(date).fromNow();
    }
  },
  methods: {
    isNotificationChecked(entry) {
      for (let index in this.selectedNotifications) {
        if (entry.id === this.selectedNotifications[index].id) {
          return index;
        }
      }
      return -1;
    },
    notificationChecked(element, entry) {
      if (element.checked) {
        for (let index in this.selectedNotifications) {
          if (entry.id === this.selectedNotifications[index].id) {
            return;
          }
        }
        this.notificationSelected = entry;
        this.selectedNotifications.push(entry);
      } else {
        for (let index in this.selectedNotifications) {
          if (entry.id === this.selectedNotifications[index].id) {
            this.selectedNotifications.splice(index, 1);
            this.notificationSelected = null
          }
        }
      }
    },
    actionSelected(event) {
      this.selectedAction = event.target.value;
      switch (this.selectedAction) {
        case "DELETE":
        case "READ":
          document.getElementById("actionSelectedElement").style.visibility =
            "visible";
          break;
        default:
          document.getElementById("actionSelectedElement").style.visibility =
            "hidden";
      }
    },
    validateSelection() {
      if (this.selectedAction === "DELETE") {
        
      }
      else if (this.selectedAction === "READ") {
        
      } else {}
    },
    selectBeneficiaryMobile(index) {
      this.selectedBeneficiary = this.customerBeneficiaries[index];
      this.detailsMobile = !this.detailsMobile;
    },
    loadCustomerBeneficiaries() {
      this.customerBeneficiaries = null;
      // retreive customer beneficiaries from service
      var formData = {
        products: [this.filters.product],
        per_page: this.perPage,
        page: this.currentPage
      };
      
      if (this.filters.keyword) {
        formData.keyword = this.filters.keyword.trim();
      }

      httpServiceCustomer.customerService
        .getBeneficiariesByFile(
          formData,
          this.companyDetails.company_id,
          Cookies.get("token")
        )
        .then(function(response) {
          return response;
        })
        .then(response => {
          if (response.status == 200) {
            this.customerBeneficiaries = response.data;
            this.beneficiariesCount = response.headers["x-up-count"]; //["Content-Length"];
          } else if (response.status == 400 || response.status == 403) {
            this.customerBeneficiaries = null;
          }
        })
        .catch(error => {});
    },
    isBeneficiaryChecked(entry) {
      for (let index in this.selectedBeneficiaries) {
        if (entry.id === this.selectedBeneficiaries[index].id) {
          return index;
        }
      }
      return -1;
    },
    beneficiaryChecked(element, entry) {
      if (element.checked) {
        for (let index in this.selectedBeneficiaries) {
          if (entry.id === this.selectedBeneficiaries[index].id) {
            return;
          }
        }
        this.selectedBeneficiaries.push(entry);
      } else {
        for (let index in this.selectedBeneficiaries) {
          if (entry.id === this.selectedBeneficiaries[index].id) {
            this.selectedBeneficiaries.splice(index, 1);
          }
        }
      }
    },
    removeBeneficiary(entry) {
      for (let index in this.selectedBeneficiaries) {
        if (entry.id === this.selectedBeneficiaries[index].id) {
          this.selectedBeneficiaries.splice(index, 1);
        }
      }
      if (this.selectedBeneficiaries.length === 0) {
        this.viewDetails(false);
      }
    },
    getProductNameMethod(productId) {
      return utils.getCompanyProductName(productId, this.companyDetails.products);
    },
  },
  watch: {
    currentPage: function() {
      // this.loadCustomerBeneficiaries();
    }
  },
  mounted() {
    // this.filters.product = this.companyDetails.products[0].card;
    // this.loadCustomerBeneficiaries();
  },
  computed: {},
  created() {}
};
</script>
<style scoped src="@/assets/styles/beneficiary.css"></style>
<style>
.notification-block {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 15px 0;
  border-bottom: 20px solid #f1f1f1;
  background: #fff;
  border-radius: 5px;
}

.notification-block .notification-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  text-align: center;
}

.notification-block .notification-title img {
  position: relative;
  width: 40px;
}  

.notification-block .notification-title h6 {
	margin: 10px 0 0;
  padding: 0;
  display: block;
}

.notification-block .notification-content {
  display: flex;
  flex-direction: column;
  flex-grow: 2;
  padding: 0 15px;
  align-items: center;
  justify-content: center;
  position: relative;
}

.notification-block .notification-content::before {
  content: '';
  position: absolute;
  top: -15px;
  height: calc(100% + 30px);
  width: 4px;
  background: #f1f1f1;
  left: 0;
}

.notification-block .notification-content::after {
  content: '';
  position: absolute;
  top: -15px;
  height: calc(100% + 30px);
  width: 4px;
  background: #f1f1f1;
  right: 0;
}

.notification-block .notification-content p {
  margin: 0;
}

.notification-block .notification-footer {
  padding: 0 10px;
  display: flex;
  align-items: center;
}
.custom-checkbox {
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.custom-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
#notificationTarget .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #4e5b59;
}
</style>
